@import styles/sass/abstracts

.main
  padding: 70px 0 98px
  background: $neutral-1
  +res-sm-max
    padding-bottom: 40px


.content
  text-align: center
  max-width: 592px
  padding: 0 $wcont-padding
  margin: 0 auto

.icon
  margin-bottom: 20px
  svg
    display: block
    +size-icon(90px)
    margin: 0 auto

.title  
  +typoH1Serif
  color: $primary-default
  +res-sm-max
    +typoH1SerifMobile

.argomenti
  text-align: center
  margin-top: 70px
  padding: 30px $wcont-padding
  background: $white
  border-radius: 16px
  +res-sm-max
    margin-top: 40px
    padding: 20px $wcont-padding 10px

.argomentiTitle
  +typoH2Serif
  margin-bottom: 22px
  +res-sm-max
    +typoH3Serif

.argomentiList
  display: flex
  flex-wrap: wrap
  justify-content: center

.argomentiItem
  margin: 0 8px 10px
  padding: 7px 10px
  background: $neutral-1
  border-radius: 6px
  border: 1px solid $neutral-4
  box-shadow: 2px 2px 6px rgba($primary-dark, .1)
  +typoTextSm
  font-weight: $fw-black
  text-transform: uppercase
  color: $primary-default



.bottom
  background: $white
  padding: 50px 0
  +res-sm-max
    padding: 30px 0 80px